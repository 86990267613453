<template>
  <div class="flex main-div main-height-width-signup">
    <div class="brand-div-login">
      <img
        src="@/assets/capacio/login/login-girl.png"
        alt=""
        class="bottom-img"
      >
      <img
        src="@/assets/capacio/login/logo.svg"
        alt=""
        class="logo-img mb-10"
      >
    </div>
    <div class="signup-form">
      <div class="flex signup-form-in">
        <div
          v-if="success"
          class="mx-3 my-3"
        >
          <h3 class="text-3xl font-bold py-4">
            Your account has been successfully created
          </h3>

          <p class="mb-4">
            Check your inbox to verify your account or <a
              class="underline"
              href="mailto:support@capacio.com"
            > contact us </a> if you have trouble signing in.
          </p>
          <!-- <div class="flex mx-auto mt-auto justify-evenly py-4">
            <router-link
              class="text-base btn signup-btn font-semibold capitalize rounded-none py-4 h-full"
              to=""
            >
              Contact us
            </router-link>
          </div> -->
          <div class="py-6 text-left">
            <router-link
              class="link text-sm font-normal no-underline"
              to="/login"
            >
              Go back to

              <span class="underline font-semibold">Sign In </span>
            </router-link>
          </div>
        </div>
        <div
          v-else
          class="w-7/12 signup-div"
        >
          <h3 class="text-3xl font-bold py-4">
            Get started with Capacio
          </h3>
          <h4 class="text-base font-normal mb-12 leading-snug">
            Signup and use free tests, only pay for the results you access.
          </h4>
          <sign-up-form

            class="mt-6"
            :show-email-msg="showEmailMsg"
            :show-invalid-msg="showInvalidEmailMsg"
            :required-msg="requiredMsg"
            :submited="submited"
            :password-mismatch="passwordMismatch"
            :invalid-coupon="showInvalidCouponMsg"
            @updateuser="setUserData"
          />
          <div
            class="py-6 flex"
            :class="{'error': showTermsMsg}"
          >
            <input
              type="checkbox"
              class="w-5"
              v-model="acceptTerms"
              :class="{'error': showTermsMsg}"
            >
            <div class="mx-4">
              <p
                class="text-xs font-normal text-color leading-snug"
                :class="{'error': showTermsMsg}"
              >
                Yes, I understand and agree to the Capacio
                <a
                  href="https://capaciotalent.com/terms-and-conditions/"
                  :class="{'error': showTermsMsg}"
                  class="link"
                  target="_blank"
                >Terms and Conditions</a>
                including the
                <a
                  :class="{'error': showTermsMsg}"
                  href="https://capaciotalent.com/privacy-policy/"
                  class="link"
                  target="_blank"
                >Privacy Policy</a>
              </p>
            </div>
          </div>
          <button
            class="btn-primary signup-btn"
            @click="signUp"
            :disabled="loading"
          >
            Sign up
          </button>
          <div class="py-6 text-left">
            <router-link
              class="link text-sm font-normal no-underline"
              to="/login"
            >
              Already a member?  <span class="underline font-semibold">Sign In </span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SignUpForm from '../components/SignUpForm.vue'
export default {

  components: {
    SignUpForm
  },
  data () {
    return {
      userData: {},
      showEmailMsg: false,
      showInvalidEmailMsg: false,
      requiredMsg: Array(7).fill(true),
      submited: false,
      success: false,
      loading: false,
      acceptTerms: false,
      showTermsMsg: false,
      passwordMismatch: false,
      showInvalidCouponMsg: false
    }
  },
  methods: {
    setUserData (val) {
      this.userData = val
    },
    async signUp () {
      this.loading = true

      this.submited = true
      this.showEmailMsg = false
      this.showInvalidEmailMsg = false
      this.showTermsMsg = false
      this.passwordMismatch = false
      this.shshowInvalidCouponMsg = false
      if (this.acceptTerms === false) {
        this.showTermsMsg = true
        this.loading = false
        return
      }

      if (this.userData.password !== this.userData.confirmPassword) {
        this.passwordMismatch = true
        this.loading = false
        return
      }

      // form validation checks if the user provided all the info
      // eslint-disable-next-line no-unused-vars
      const inputKeys = Object.keys(this.userData)

      inputKeys.slice(0, inputKeys.length - 1).forEach((key, index) => {
        if (this.userData[key] !== '') {
          this.requiredMsg[index] = false
        }
      })

      if (this.requiredMsg.slice(0, this.requiredMsg.length - 2).includes(true)) {
        this.loading = false
        return
      }

      if (this.userData.coupon && this.userData.coupon === '') {
        this.userData.coupon = null
      }

      const success = await this.$store.dispatch('USER/signUp', this.userData)

      if (success === 'success') {
        this.success = true
        this.userData = null
      } else if (success === 'email_used') {
        this.showEmailMsg = true
      } else if (success === 'email_invalid') {
        this.showInvalidEmailMsg = true
      } else if (success === 'invalid_coupon') {
        this.showInvalidCouponMsg = true
      }

      this.loading = false
    }
  }
}
</script>

<style lang="scss">
@import "../signup.scss";
</style>
