<template>
  <div class="form-control">
    <div class="grid md:grid-cols-2 gap-4">
      <div class="form-control">
        <input
          class=" py-6 px-4 mb-4 leading-tight border text-base focus:outline-none"
          type="text"
          placeholder="First name"
          data-theme="mytheme"
          :disabled="disabled"
          v-model="firstName"
          :class="{'input-error': requiredMsg[0] && submited}"
          @change="$emit('updateuser', user)"
        >
      </div>
      <div class="form-control">
        <input
          v-model="lastName"
          :disabled="disabled"
          data-theme="mytheme"
          class=" py-6 px-4 mb-4 leading-tight border text-base focus:outline-none"
          type="text"
          placeholder="Last name"
          :class="{'input-error': requiredMsg[1] && submited}"
          @change="$emit('updateuser', user)"
        >
      </div>
    </div>
    <div class="grid grid-cols-1 ">
      <div class="form-control">
        <input
          class=" py-6 px-4 mb-4 leading-tight border text-base focus:outline-none"
          v-model="email"
          :disabled="disabled"
          data-theme="mytheme"
          type="text"
          placeholder="Email"
          :class="{'input-error': requiredMsg[2] && submited || showEmailMsg || showInvalidMsg}"
          @change="$emit('updateuser', user)"
        >
      </div>
      <span
        v-if="showEmailMsg"
        class=""
      >* Email already in use</span>
      <span
        v-if="showInvalidMsg"
        class="text-red-600"
      >* Invalid Email</span>

      <div class="form-control">
        <input
          v-model="organisation"
          :disabled="disabled"
          class=" py-6 px-4 mb-4 leading-tight border text-base focus:outline-none"
          id="grid-first-name"
          type="text"
          placeholder="Organisation"
          :class="{'input-error': requiredMsg[3] && submited}"
          @change="$emit('updateuser', user)"
        >
      </div>
      <div class="form-control">
        <input
          class=" py-6 px-4 mb-4 leading-tight border text-base focus:outline-none"
          :disabled="disabled"
          v-model="password"
          data-theme="mytheme"
          type="password"
          placeholder="Password"
          :class="{'input-error': requiredMsg[4] && submited || passwordMismatch}"
          @change="$emit('updateuser', user)"
        >
      </div>
      <div class="form-control">
        <input
          class=" py-6 px-4 mb-4 leading-tight border text-base focus:outline-none"
          :disabled="disabled"
          v-model="confirmPassword"
          data-theme="mytheme"
          type="password"
          placeholder="Confirm password"
          :class="{'input-error': requiredMsg[5] && submited || passwordMismatch}"
          @change="$emit('updateuser', user)"
        >
      </div>
      <span
        v-if="passwordMismatch"
        class="text-red-600"
      >* Passwords does not match</span>
      <div class="form-control">
        <input
          :disabled="disabled"
          v-model="coupon"
          data-theme="mytheme"
          class=" py-6 px-4 mb-4 leading-tight border text-base focus:outline-none"
          type="password"
          placeholder="Coupon code"
          :class="{'input-error': invalidCoupon && submited}"
          @change="$emit('updateuser', user)"
        >
      </div>
      <span
        v-if="invalidCoupon"
        class="text-red-600"
      >* Invalid Coupon</span>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    showEmailMsg: {
      type: Boolean
    },
    showInvalidMsg: {
      type: Boolean
    },
    passwordMismatch: {
      type: Boolean
    },
    invalidCoupon: {
      type: Boolean
    },
    requiredMsg: {
      type: Array,
      required: true
    },
    submited: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    }
  },
  data () {
    return {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      organisation: '',
      confirmPassword: '',
      coupon: null
    }
  },
  computed: {
    user () {
      return {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email.toLowerCase(),
        organisation: this.organisation,
        password: this.password,
        confirmPassword: this.confirmPassword,
        coupon: this.coupon
      }
    }
  }

}
</script>

<style>

</style>
